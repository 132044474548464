import React from 'react'
import { StyledLoaderContainer, StyledLoadingRinger, StyledLoadingRing } from './styles'

const Loader = () => (
  <StyledLoaderContainer>
    <StyledLoadingRinger>
      <StyledLoadingRing />
      <StyledLoadingRing />
      <StyledLoadingRing />
    </StyledLoadingRinger>
  </StyledLoaderContainer>
)

export default Loader