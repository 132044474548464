import { SbGatsbyStory } from "gatsby-source-storyblok";
import React from "react";

export const sbBridge = (
  previewStory: { uuid: string },
  setStory: React.Dispatch<React.SetStateAction<SbGatsbyStory | undefined>>
) => {
  const { StoryblokBridge, location } = window;

  if (location.search.includes("_storyblok")) {
    if (typeof StoryblokBridge !== "undefined") {
      // first load the bridge and then attach the events
      const storyblokInstance = new StoryblokBridge();

      // reloaded project on save and publish
      storyblokInstance.on(["published", "change"], () => {
        location.reload();
      });

      // when input, change the event story.
      storyblokInstance.on(["input"], (event) => {
        if (event?.story?.uuid === previewStory.uuid) {
          // @ts-ignore: should have a story rendered by here.
          setStory(event.story);
        }
      });
    }
  }
};
