import React, { FC, useEffect, useState } from "react";

import {
  SbGatsbyStory,
  apiPlugin,
  getStoryblokApi,
  storyblokInit,
} from "gatsby-source-storyblok";

import Layout from "../components/layout";
import Loader from "../components/loader";
import { sbBridge } from "../utils/sbBridge";
import { paramFinder } from "../utils/slug";

storyblokInit({
  accessToken: process.env.GATSBY_SB_PREVIEW_TOKEN,
  use: [apiPlugin],
});

interface PreviewProps {
  data: any;
}

const Preview: FC<PreviewProps> = () => {
  const [story, setStory] = useState<SbGatsbyStory | undefined>();

  useEffect(() => {
    if (window) {
      const slug = paramFinder("slug") || "home";
      if (slug) {
        getStoryblokApi()
          .get(`cdn/stories/${slug}`, {
            version: "draft",
            cv: Date.now(),
          })
          .then(({ data }) => {
            if (data.story) {
              setStory(data.story);
              // Check if editor mode should be displayed
              sbBridge(data.story, setStory);
            }
          })
          .catch((error) => console.log(error));
      }
    }
  }, []); // it's important to run the effect only once to avoid multiple event attachment

  if (!story?.content) return <Loader />;

  return <Layout content={story.content} path={story.full_slug} />;
};

export default Preview;
